import React from "react";
import { Link } from "react-router-dom";

const PricingTier = ({ name, price, features, popular, ctaText }) => (
    <div
        className={`bg-white shadow-lg rounded-lg overflow-hidden ${
            popular ? "border-2 border-indigo-500" : ""
        }`}
    >
        {popular && (
            <div className="bg-indigo-500 text-white text-center py-2 text-sm font-semibold">
                Most Popular
            </div>
        )}
        <div className="px-6 py-8">
            <h3 className="text-2xl font-bold text-gray-900 text-center">
                {name}
            </h3>
            <div className="mt-4 flex justify-center items-baseline">
                <span className="text-5xl font-extrabold text-gray-900">
                    ${price}
                </span>
                <span className="ml-1 text-xl font-medium text-gray-500">
                    /month
                </span>
            </div>
            <ul className="mt-6 space-y-4">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                        <svg
                            className="flex-shrink-0 h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                        <span className="ml-3 text-base text-gray-700">
                            {feature}
                        </span>
                    </li>
                ))}
            </ul>
            <div className="mt-8">
                <Link
                    to="/register"
                    className={`block w-full px-6 py-3 text-center font-medium rounded-md text-white ${
                        popular
                            ? "bg-indigo-600 hover:bg-indigo-700"
                            : "bg-gray-800 hover:bg-gray-700"
                    }`}
                >
                    {ctaText}
                </Link>
            </div>
        </div>
    </div>
);

const PricingSection = () => {
    const pricingTiers = [
        {
            name: "Basic",
            price: 59,
            features: [
                "1 Clinician",
                "Appointment Scheduling",
                "Client Management",
                "Intake Forms",
            ],
            ctaText: "Start Free 7-Day Trial",
        },
        {
            name: "Pro",
            price: 99,
            features: [
                "Up to 5 Clinicians",
                "Appointment Scheduling",
                "Client Management",
                "Intake Forms",
                "Advanced Payroll Calculations",
                "HR Management",
                "Custom Reporting",
                "QuickBooks Integration",
            ],
            popular: true,
            ctaText: "Start Free 7-Day Trial",
        },
        {
            name: "Expert",
            price: 299,
            features: [
                "Up to 25 Clinicians",
                "Appointment Scheduling",
                "Client Management",
                "Intake Forms",
                "Advanced Payroll Calculations",
                "Custom Reporting",
                "QuickBooks Integration",
                "Priority Support",
            ],
            ctaText: "Start Free 7-Day Trial",
        },
    ];

    return (
        <div className="bg-gray-100 py-16" id="pricing">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Simple pricing for everyone
                    </h2>
                    <p className="mt-4 text-xl text-gray-600">
                        Streamline your clinic management with our tailored solutions.
                        Start your free 7-day trial today.
                    </p>
                </div>
                <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
                    {pricingTiers.map((tier, index) => (
                        <PricingTier key={index} {...tier} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PricingSection;
