import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Login thunk
export const loginUserAsync = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/auth/login', { email, password });
      localStorage.setItem('token', response.data.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Updated register thunk to match the expected name
export const registerUserAsync = createAsyncThunk(
  'auth/register',
  async ({ email, password, clinicName, clinicType, subscriptionPlan }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/auth/register', {
        email,
        password,
        clinicName,
        clinicType,
        subscriptionPlan
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Logout thunk
export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await axios.post('/api/auth/logout');
      localStorage.removeItem('token');
      return null;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Get current user thunk
export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/auth/me');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
