import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-3xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Privacy Policy</h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Effective Date: 9/15/2024
          </p>
        </div>
        <div className="mt-12 text-base text-gray-500 space-y-8">
          <section>
            <h3 className="text-lg font-medium text-gray-900">Introduction</h3>
            <p className="mt-2">
              Simone's Clinic Management ("we", "our", or "us") is committed to protecting the privacy of individuals who use our website and services (collectively, the "Service"). This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information in compliance with applicable privacy laws, including the Personal Information Protection and Electronic Documents Act (PIPEDA) in Canada.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Information We Collect</h3>
            <ul className="mt-2 list-disc list-inside">
              <li>Account Information: When you register for our Service, we collect personal information such as your name, email address, and any other details you provide.</li>
              <li>Usage Information: We collect information about your interactions with the Service, including features you use, pages you visit, and actions you take.</li>
              <li>Device Information: We collect information about the device you use to access the Service, including hardware model, operating system, and browser type.</li>
              <li>Cookies and Tracking Technologies: We use cookies, web beacons, and other tracking technologies to collect information about your use of our website. This may include details like your IP address, browser type, device information, and browsing behavior.</li>
            </ul>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">How We Use Your Information</h3>
            <p className="mt-2">We use your personal information for the following purposes:</p>
            <ul className="mt-2 list-disc list-inside">
              <li>Service Provision: To provide, maintain, and improve the Service.</li>
              <li>Communication: To communicate with you regarding updates, promotional offers, or other information related to the Service.</li>
              <li>Legal Compliance: To comply with applicable laws and regulations, including those related to data protection and privacy.</li>
              <li>Security and Fraud Prevention: To protect our users and the Service from security risks and fraud.</li>
              <li>Analytics and Improvements: To analyze website traffic and user behavior using cookies and analytics tools, which helps us improve the functionality and user experience of the Service.</li>
            </ul>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Information Stored on Your Device</h3>
            <p className="mt-2">
              As part of providing the Service, we use React and Redux for state management and user interface interactions. This may result in certain data being stored locally on your device, including but not limited to:
            </p>
            <ul className="mt-2 list-disc list-inside">
              <li>Session Data: Information related to your current session, such as temporary user preferences and state data, may be stored in your browser's local storage or session storage.</li>
              <li>Application State: Certain information, such as form inputs, settings, and user-specific data required for seamless operation, may be cached on your device for performance optimization.</li>
            </ul>
            <p className="mt-2">
              We do not store sensitive personal information (such as passwords or financial details) in local storage. The information stored locally is intended to improve your experience by ensuring the app can maintain functionality even when offline or when there are network issues.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Cookies and Tracking Technologies</h3>
            <p className="mt-2">We use cookies and similar tracking technologies to:</p>
            <ul className="mt-2 list-disc list-inside">
              <li>Understand User Behavior: Cookies help us understand how users navigate through our site, so we can make improvements.</li>
              <li>Personalize Content: We use cookies to remember your preferences and customize your experience.</li>
              <li>Analytics and Performance: We use analytics tools, such as Google Analytics, to collect information on how our Service is used, track website traffic, and understand trends.</li>
            </ul>
            <p className="mt-2">
              You can control the use of cookies through your browser settings. However, disabling cookies may limit your ability to use certain features of our Service.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Sharing Your Information</h3>
            <p className="mt-2">
              We do not sell, rent, or trade your personal information to third parties. However, we may disclose personal information under the following circumstances:
            </p>
            <ul className="mt-2 list-disc list-inside">
              <li>Service Providers: We may share personal information with trusted third-party service providers who assist us in operating the Service (e.g., hosting, email delivery, payment processing).</li>
              <li>Legal Requirements: We may disclose your personal information if required by law, such as to comply with a legal process or government request.</li>
              <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our business, personal information may be transferred as part of the transaction.</li>
            </ul>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Analytics Tools</h3>
            <p className="mt-2">
              We use third-party analytics tools, such as Google Analytics, to collect and analyze information about how you use our Service. These tools collect data such as:
            </p>
            <ul className="mt-2 list-disc list-inside">
              <li>Pages visited</li>
              <li>Time spent on pages</li>
              <li>Links clicked</li>
              <li>Traffic sources</li>
            </ul>
            <p className="mt-2">
              Analytics data is anonymized and used to improve the Service's performance, identify popular content, and enhance user experience. You can opt out of Google Analytics by installing the Google Analytics Opt-out Browser Add-on.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Your Rights</h3>
            <p className="mt-2">
              Under Canadian privacy laws, you have certain rights regarding your personal information. You may:
            </p>
            <ul className="mt-2 list-disc list-inside">
              <li>Access: Request access to your personal information that we hold.</li>
              <li>Correction: Request corrections to any inaccuracies in your personal information.</li>
              <li>Withdrawal of Consent: Withdraw your consent for certain types of data processing.</li>
              <li>Deletion: Request the deletion of your personal information, subject to legal and contractual restrictions.</li>
            </ul>
            <p className="mt-2">
              To exercise any of these rights, please contact us using the information provided below. We will respond to your request in accordance with applicable laws.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Security of Your Information</h3>
            <p className="mt-2">
              We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">International Transfers</h3>
            <p className="mt-2">
              If we transfer your personal information outside of Canada, we ensure that appropriate safeguards are in place to protect your information in accordance with Canadian privacy laws.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Retention of Personal Information</h3>
            <p className="mt-2">
              We retain your personal information only as long as necessary to fulfill the purposes for which it was collected or as required by law.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Changes to This Privacy Policy</h3>
            <p className="mt-2">
              We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any significant changes by posting the updated Privacy Policy on our website, with the effective date noted at the top.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Contact Us</h3>
            <p className="mt-2">
              If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
            </p>
            <p className="mt-2">
              Email: privacy@simonesclinicmanagement.com
            </p>
            <p className="mt-2">
              Mailing Address:<br />
              Privacy Officer<br />
              Simone's Clinic Management<br />
              [Your Address Here]<br />
              Ontario, Canada
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;