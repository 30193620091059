import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import WhyUseSection from './components/WhyUseSection';
import BenefitsSection from './components/BenefitsSection';
import FeatureSection from './components/FeatureSection';
import PricingSection from './components/PricingSection';
import CTASection from './components/CTASection';
import Footer from './components/Footer';

const LandingPage = () => {
  return (
    <div className="bg-white">
      <Header />
      <main>
        <HeroSection />
        <WhyUseSection />
        <BenefitsSection />
        <div id="pricing">
          <PricingSection />
        </div>
        <div id="features">
          <FeatureSection />
        </div>
        <CTASection />
      </main>
      <Footer />
    </div>
  );
};

export default LandingPage;
