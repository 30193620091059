import React from 'react';

const TermsOfService = () => {
  return (
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-3xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Terms of Service</h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Effective Date: 9/15/2024
          </p>
        </div>
        <div className="mt-12 text-base text-gray-500 space-y-8">
          <section>
            <h3 className="text-lg font-medium text-gray-900">Introduction</h3>
            <p className="mt-2">
              Welcome to Simone's Clinic Management ("we," "our," or "us"). By accessing or using our website and services (collectively, the "Service"), you agree to comply with and be bound by these Terms of Service ("Terms"). Please read these Terms carefully. If you do not agree to these Terms, you must not use the Service.
            </p>
            <p className="mt-2">
              These Terms are governed by the laws of Ontario, Canada, and comply with the Personal Information Protection and Electronic Documents Act (PIPEDA) and other relevant Canadian privacy laws.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Use of the Service</h3>
            <ul className="mt-2 list-disc list-inside">
              <li><strong>Eligibility:</strong> You must be at least 18 years of age to use the Service. By using the Service, you represent and warrant that you are legally permitted to enter into this agreement.</li>
              <li><strong>Account Responsibility:</strong> You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized access to your account.</li>
              <li><strong>Prohibited Activities:</strong> You agree not to use the Service for any unlawful purposes or in a manner that violates these Terms. Prohibited activities include, but are not limited to:
                <ul className="ml-6 mt-2 list-disc list-inside">
                  <li>Violating any laws or regulations.</li>
                  <li>Infringing on intellectual property rights.</li>
                  <li>Using the Service to transmit harmful or malicious software.</li>
                  <li>Engaging in fraudulent or misleading activities.</li>
                </ul>
              </li>
            </ul>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Information and Data</h3>
            <p className="mt-2">
              By using the Service, you agree to the collection, use, and disclosure of your information as outlined in our Privacy Policy. We use React and Redux technologies, which may store non-sensitive information locally on your device to optimize performance and user experience.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Intellectual Property</h3>
            <p className="mt-2">
              All content, features, and functionality of the Service, including but not limited to text, graphics, logos, and software, are owned by or licensed to Simone's Clinic Management and are protected by applicable copyright, trademark, and other intellectual property laws.
            </p>
            <p className="mt-2">
              You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use the Service for your personal or business purposes. You must not modify, reproduce, distribute, or create derivative works from the content of the Service without our prior written consent.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Modifications to the Service</h3>
            <p className="mt-2">
              We reserve the right to modify, suspend, or discontinue any part of the Service at any time without notice. We will not be liable to you or any third party for any modification, suspension, or discontinuation of the Service.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Limitation of Liability</h3>
            <p className="mt-2">
              To the fullest extent permitted by law, Simone's Clinic Management, its affiliates, directors, officers, employees, and agents shall not be liable for any indirect, incidental, special, or consequential damages arising from or related to your use of the Service, even if we have been advised of the possibility of such damages.
            </p>
            <p className="mt-2">
              Our total liability to you for any claims arising from or related to the use of the Service shall be limited to the amount you paid, if any, to use the Service.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Disclaimer of Warranties</h3>
            <p className="mt-2">
              The Service is provided on an "as is" and "as available" basis without warranties of any kind, either express or implied. We do not warrant that the Service will be uninterrupted, error-free, or secure, nor do we make any warranty regarding the accuracy or reliability of any information obtained through the Service.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Indemnification</h3>
            <p className="mt-2">
              You agree to indemnify and hold Simone's Clinic Management, its affiliates, directors, officers, employees, and agents harmless from any claims, damages, liabilities, costs, and expenses, including reasonable legal fees, arising from or related to your use of the Service or your breach of these Terms.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Mandatory Binding Arbitration</h3>
            <ol className="mt-2 list-decimal list-inside">
              <li><strong>Arbitration Agreement:</strong> You and Simone's Clinic Management agree that any disputes or claims arising out of or relating to these Terms or the Service shall be resolved through binding arbitration, rather than in court, except for matters that qualify for small claims court.</li>
              <li><strong>Arbitration Process:</strong> The arbitration shall be conducted by a single arbitrator in accordance with the Arbitration Act, 1991 (Ontario). The arbitration will take place in Ontario, Canada, and will be conducted in English. The decision of the arbitrator will be final and binding, and judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</li>
              <li><strong>Class Action Waiver:</strong> You agree that any arbitration shall be conducted only in your individual capacity, and not as a plaintiff or class member in any purported class or representative proceeding. You expressly waive your right to participate in a class action lawsuit or class-wide arbitration.</li>
              <li><strong>Opt-Out:</strong> You may opt out of this mandatory arbitration provision by providing written notice to us within 30 days of accepting these Terms. If you opt out, you agree to resolve disputes in the courts located in Ontario, Canada.</li>
            </ol>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Governing Law</h3>
            <p className="mt-2">
              These Terms shall be governed by and construed in accordance with the laws of Ontario, Canada, without regard to conflict of law principles. Any legal actions related to these Terms or the Service shall be brought exclusively in the courts located in Ontario, Canada.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Termination</h3>
            <p className="mt-2">
              We may terminate or suspend your account or access to the Service at any time, without prior notice or liability, if you breach these Terms or for any other reason. Upon termination, your right to use the Service will immediately cease.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Changes to These Terms</h3>
            <p className="mt-2">
              We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the updated Terms on this page, and the "Effective Date" will be revised accordingly. By continuing to use the Service after the updated Terms are posted, you agree to be bound by the modified Terms.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-gray-900">Contact Information</h3>
            <p className="mt-2">
              If you have any questions or concerns about these Terms, please contact us at:
            </p>
            <p className="mt-2">
              Email: support@simonesclinicmanagement.com
            </p>
            <p className="mt-2">
              Mailing Address:<br />
              Simone's Clinic Management<br />
              [Your Address Here]<br />
              Ontario, Canada
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;