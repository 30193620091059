import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

// Import reducers
import authReducer from "./slices/authSlice";

// Import thunks
import {
    loginUserAsync,
    registerUserAsync,
    logout,
    getCurrentUser,
} from "./thunks/authThunk";

const rootReducer = combineReducers({
    auth: authReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    loginUserAsync,
                    registerUserAsync,
                    logout,
                    getCurrentUser,
                },
            },
        }),
});

export default store;
