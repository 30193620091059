import React from 'react';

const FeatureCard = ({ title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h4 className="text-xl font-bold text-indigo-900 mb-2">{title}</h4>
    <p className="text-gray-600">{description}</p>
  </div>
);

const WhyUseSection = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Why use <span className="text-indigo-600">Simone Clinic Management</span>?
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Our software is the only tool that helps you automate your clinic management to improve efficiency, enhance patient care, and increase revenue.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-8">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Streamline Your Clinic Operations
              </h3>
              <p className="text-gray-600 mb-6">
                Automate routine tasks and manage your clinic more efficiently, allowing you to focus on what matters most - patient care.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6">
              <FeatureCard
                title="Smart Appointment Scheduling"
                description="Optimize your clinic's schedule with our intelligent booking system, reducing no-shows and maximizing your time."
              />
              <FeatureCard
                title="Automated Patient Management"
                description="Effortlessly manage patient records, medical histories, and follow-ups with our comprehensive system."
              />
            </div>
          </div>

          <div className="space-y-8">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Enhance Patient Care and Communication
              </h3>
              <p className="text-gray-600 mb-6">
                Improve patient engagement and satisfaction with our integrated communication tools and personalized care features.
              </p>
            </div>
            <div className="relative">
              <img
                src="https://placehold.co/600x400?text=Dashboard+Preview"
                alt="Clinic Management Dashboard"
                className="rounded-lg shadow-xl"
              />
              <img
                src="https://placehold.co/400x300?text=Patient+Profile"
                alt="Patient Profile"
                className="absolute top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 w-2/3 rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>

        <div className="mt-16 flex justify-center space-x-2">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className="w-2 h-2 rounded-full bg-indigo-500"
            ></div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUseSection;